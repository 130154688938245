import request from '@/utils/request'

export function getActivityList( params,data){
    return request({
        url: '/appreq/activity/getActivityList',
        method: 'post',
        headers: {
            isToken: false
        },
        data,
        params
    })
}
export function getActivityListNotPage(data){
    return request({
        url: '/appreq/activity/getActivityListNotPage',
        method: 'post',
        headers: {
            isToken: false
        },
        data,
    })
}
export function getActivityTypeList(){
    return request({
        url: '/appreq/activityType/getAllActivityType',
        headers: {
            isToken: false
        },
        method: 'get',
    })
}
export function getDetail(id){
    return request({
        url: '/appreq/activity/getDetail/'+id,
        headers: {
            isToken: false
        },
        method: 'get',
    })
}
export function getSignupList(params,data){
    return request({
        url: '/appreq/activitySignup/getSignupList',
        method: 'post',
        data,
        params
    })
}
export function sign(data){
    return request({
        url: '/appreq/sinup/sign',
        method: 'post',
        data,
    })
}
export function getSign(id){
    return request({
        url: '/appreq/sinup/'+id,
        method: 'get',
    })
}
export function checkSign(id){
    return request({
        url: '/appreq/sinup/check/'+id,
        method: 'get',
    })
}
export function checkSignOff(id){
    return request({
        url: '/appreq/signOff/check/'+id,
        method: 'get',
    })
}
export function getCommentList(params,data){
    return request({
        url: '/appreq/activityComment/getCommentList',
        method: 'post',
        params,
        data
    })
}
export function addComment(data){
    return request({
        url: '/appreq/activityComment/comment',
        method: 'post',
        data,
    })
}

export function claim(data){
    return request({
        url: '/appreq/activitySignup/claim',
        method: 'post',
        data,
    })
}
export function signup(data){
    return request({
        url: '/appreq/activitySignup/signup',
        method: 'post',
        data,
    })
}
export function issueActivity(data){
    return request({
        url: '/appreq/activity/issueActivity',
        method: 'post',
        data,
    })
}
export function getMyPubList(params){
    return request({
        url: '/appreq/activity/getMyPubList',
        method: 'get',
        params,
    })
}
//签退
export function signoff(data){
    return request({
        url: '/appreq/signOff/sign',
        method: 'post',
        data,
    })
}
export function getSignOff(id){
    return request({
        url: '/appreq/signOff/'+id,
        method: 'get',
    })
}
export function getTypeScore(typeId){
    return request({
        url: '/appreq/activityTypeScore/getTypeScore/'+typeId,
        method: 'get',
        headers: {
            loading :false
        },
    })
}
export function getHotActivityList( params,data){
    return request({
        url: '/appreq/activity/getHotActivityList',
        method: 'post',
        headers: {
            isToken: false
        },
        data,
        params
    })
}
